import React, { useContext } from "react";
import Img from "gatsby-image";
import Slider from "react-slick";

import Context from "./context";
import PreviousButton from "./previous-button";
import NextButton from "./next-button";

const ServiceSlider = ({ margin }) => {
  const { data } = useContext(Context);

  const settings = {
    slidesToShow: 1,
    dots: true,
    prevArrow: <PreviousButton />,
    nextArrow: <NextButton />,
  };

  return (
    <Slider
      className={`slider slider-services${margin ? " mb-8" : ""}`}
      {...settings}
    >
      {data.contentfulService.gallery.map((node, i) => (
        <Img
          fluid={{ ...node.fluid, aspectRatio: 460 / 610 }}
          alt={node.title}
          key={i}
        />
      ))}
    </Slider>
  );
};

export default ServiceSlider;
