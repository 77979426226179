import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ServiceSlider from "../components/service-slider";
import ServicesSlider from "../components/services-slider";
import ServiceModal from "../components/service-modal";

const ServiceTemplate = ({ data, pageContext: context }) => {
  const intl = useIntl();

  const {
    title,
    main_title,
    short_description,
    content,
    icon_gallery,
    gallery,
    thumbnail,
  } = data.contentfulService;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;

  function getPage(category) {
    return allPages.find((page) => page.category === category);
  }

  return (
    <Layout>
      <Seo title={title} image={thumbnail && thumbnail.file.url}>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "${getPage("Services").title}",
                  "item": "${siteUrl}/${context.locale}/${
                    getPage("Services").slug
                  }/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${title}"
                }
              ]
            }
          `}
        </script>
      </Seo>
      <article>
        <section className="section section-white">
          <div className="introduction introduction-clouds">
            <div className="introduction-container">
              <div className="introduction-inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <p className="eyebrow">{title}</p>
                      {renderRichText(main_title, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (node, children) => (
                            <h1>{children}</h1>
                          ),
                        },
                        renderMark: {
                          [MARKS.BOLD]: (text) => <>{text}</>,
                        },
                      })}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-gradient">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {renderRichText(short_description, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p className="lead">{children}</p>
                    ),
                  },
                })}
              </div>
            </div>
            {content && (
              <div className="row mt-16 mt-md-30">
                <div className="col-md-6 text-blue-dark">
                  {renderRichText(content)}
                  {icon_gallery && (
                    <div className="services">
                      {icon_gallery.map((node, i) => {
                        const {
                          url,
                          details: {
                            image: { width, height },
                          },
                        } = node.file;

                        return (
                          <div className="services-item" key={i}>
                            <ServiceModal node={node}>
                              <img
                                src={url}
                                width={width}
                                height={height}
                                alt={node.title}
                              />
                              <p>
                                <span>{node.title}</span>
                              </p>
                            </ServiceModal>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="col-md-5 offset-md-1 mt-16 mt-md-0">
                  {gallery && (
                    <LazyLoad>
                      <ServiceSlider margin={true} />
                    </LazyLoad>
                  )}
                  <Link
                    className="button"
                    to={`/${intl.locale}/${
                      getPage("Groupes et séminaires").slug
                    }/`}
                  >
                    <FormattedMessage id="book-hotel" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
        <aside className="section section-blue overflow-hidden">
          <div className="container">
            <h2>{data.contentfulPage.main_title}</h2>
            <p className="text-blue-dark">{data.contentfulPage.subtitle}</p>
            <div className="row mt-6 mt-md-10">
              <div className="col-8 col-sm-10 col-lg-12">
                <LazyLoad>
                  <ServicesSlider />
                </LazyLoad>
              </div>
            </div>
          </div>
        </aside>
      </article>
    </Layout>
  );
};

export default ServiceTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      address
      phone
      booking_id
      place_id
      business_url
      hero_image {
        file {
          url
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulPage(
      contentful_id: { eq: "45OcEyajvmUzF2zPKixst5" }
      node_locale: { eq: $locale }
    ) {
      main_title
      subtitle
    }
    contentfulService(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title {
        raw
      }
      short_description {
        raw
      }
      content {
        raw
      }
      icon_gallery {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
      gallery {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      thumbnail {
        file {
          url
        }
      }
    }
    allContentfulService(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
